import Vue from 'vue'
import VueRouter from 'vue-router'

const DefaultFooter = () => import(/* webpackChunkName: "default-footer" */ '@/layouts/DefaultFooter.vue')
const Default = () => import(/* webpackChunkName: "default" */ '@/layouts/Default.vue')

Vue.use(VueRouter)

const routes = [
  {
    path: '/app/orders-and-messages',
    component: Default,
    props: true,
    children: [
      {
        path: '',
        name: 'OrdersAndMessages',
        components: {
          default: () => import(/* webpackChunkName: "Home" */ '../views/Home.vue'),
          footer: DefaultFooter
        }
      },
      {
        path: 'ticket',
        name: 'Request',
        components: {
          default: () => import(/* webpackChunkName: "Request" */ '../views/Request.vue'),
          footer: DefaultFooter
        }
      },
      {
        path: 'ticket/:uuid',
        name: 'Template',
        components: {
          default: () => import(/* webpackChunkName: "Template" */ '../views/Template.vue'),
          footer: DefaultFooter
        },
        props: true
      },
      {
        path: 'tickets/:uuid',
        name: 'Detail',
        components: {
          default: () => import(/* webpackChunkName: "Detail" */ '../views/Detail.vue'),
          footer: DefaultFooter
        },
        props: true
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
